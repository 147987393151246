/* QuickReset */ * {margin: 0; box-sizing: border-box;}

html, body {
  margin: 0;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* QuickReset */ * {margin: 0; box-sizing: border-box;}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

@keyframes blink {
  0% {opacity: 0}
  49%{opacity: 0}
  50% {opacity: 1}
}

.app-loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #919191;
}

.app-loader svg {
  width: 100px;
  height: 100px;
  animation: blink .7s infinite;
  margin: 1em;
}

input, select, button { font-size: 100%; }

.feedback{
  width: 100%;
  padding: 8px 10px 8px 10px;
  border-radius: 8px 8px 0 0;
  justify-self:center;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  z-index:1;
  
}
.feedback-comment {
    width: 100%;
    height: 100px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    font-size: 14px;
    resize: none;
    align-items: center;
    margin-bottom: 4px;
}
.feedback-title{
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  padding-bottom: 8px;
  padding-top: 8px;
 
 }

.additional-feedback{
  font-size:16px;
  text-align: center;
  margin-bottom: 8px;

}

.chat {
  --rad: 16px;
  --rad-sm: 2px;
  font-size: 16px;
  line-height: 1.5;
  display: grid;
  height: 100%;
  grid-template-rows: 100px auto 30px 50px;
  max-width: 500px;
  
}

.chat.\+feedback {
  grid-template-rows: 100px auto 320px;
}

.chat.\+feedback .feedback {
  display: block;
}
.chat.\+feedback .new-message, .chat.\+feedback .feedback-cta, .feedback {
  display: none;
}

.submit-feedback{
  display:flex;
  font-weight: 600;
}

.rating {
  display:flex; 
  justify-content: center;
  gap: 10px;
  
}

.rating input {
  display: none;
}

.feedback-cta .active svg path, .rating label:has(input:checked) svg>path {
  fill: black;
}

.feedback-cta .active svg>rect, .rating label:has(input:checked) svg>rect {
  fill: rgba(234, 74, 109, 0.30);
}

.feedback-cta{
  display:flex;
  text-decoration:underline;
  font-size: 14px;
  color: black;
  justify-content: center;
}

.property-card {
  display: flex;
  gap: 10px;
  padding: 12px;
  margin: 12px 12px 0;
  overflow: hidden;
  border-bottom: solid #D9D9D9 1px;
  border-radius: 0.25rem;
  background: white;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.25);
  z-index:2;
}

.property-card img {
  max-width: 100%;
  max-height: 100%;
  height: 200px;
}
.property-card .logo {
  width: 60px;
  height: 60px;
}

.property-card .logo img {
  height: auto;
  min-height: 10px;
}

.property-details{
  flex: 1 0 0;
  font-size: medium;
  font-weight: 400;
  align-items: center;
  line-height: 19px;

  & div:nth-child(2) {
    font-weight:600;

  @media screen and (min-width: 395px) {
    line-height: 24px;
    

  @media screen and (min-width:769px) {
    line-height: 26px;


}}}}


.message-item {
  display: grid;
  grid-template-columns: 40px auto;
  align-items: flex-start;
  padding: 4px;
  margin-bottom: 12px;
  margin-top: 12px;
}

.messages {
  overflow-y: auto;
  padding: 16px;
}

.message-limit-error {
  color: #EA4A6D;
  text-align: center;
}

.new-message {
  padding: 8px;
  display: flex;
  align-items:center;
  gap: 10px;
  border-top: solid #D9D9D9 1px;
}


.new-message input{
  height: 100%;
  background-color: transparent;
  width: 90%;
  border: none;
  padding:12px;
  
}

.new-message input:focus{
  outline: none;
}

.new-message input:disabled{
  cursor: not-allowed;
  color: #D9D9D9;
}

.conversations p {
  padding: 10px;
  cursor: pointer;
}

.msg {
  position: relative;
  max-width: 90%;
  padding: 12px 20px;
  background-color: #f1f1f1;
  z-index:1;
}

.sent .msg {
  border-radius: var(--rad) var(--rad-sm) var(--rad-sm) var(--rad);
  background: #EA4A6D;
  color: #fff;
  /* moves it to the right */
  margin-left: auto;
  z-index:1;
}

.rcvd .sender {
  background-image: url("https://site-static.propertynews.com/images/liv/icon-small-light-pink.png"); 
  background-size: 30px;
  background-repeat: no-repeat;
  background-position: bottom left;
  height: 100%;
  margin-bottom: 12px;
  z-index:1;

}

.rcvd .msg {
  border-radius: var(--rad) var(--rad) var(--rad) var(--rad-sm);
  background: #f1f1f1;
  color: #555;
  /* moves it to the left */
  margin-right: auto;
  z-index:1;
}

/* Improve radius for messages group */

.sent:first-child .msg,
.rcvd+.sent .msg {
  border-top-right-radius: var(--rad);
}

.rcvd:first-child .msg,
.sent+.rcvd .msg {
  border-top-left-radius: var(--rad);
}

.send-button{
  padding: 5px 16px;
  height: 35px;
  border: 0;
  border-radius: 4px;
  background: #EA4A6D;
  color: #ffffff;
  font-weight: 600;
}


.cancel{
  padding: 0 1rem;
  font-size: 17px;
  line-height: 40px;
  min-height: 40px;
  color: #919191;
  border: 0;
  background: transparent;
  margin-right: auto;
}

input[type=submit] {
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 17px;
  line-height: 40px;
  min-height: 40px;
  border-radius: 4px;
  color: white;
  border: 1px solid #EA4A6D;
  background-color:#EA4A6D;
  font-weight: 600;
  
}
.c-button {
  padding: 0 1rem;
  appearance: none;
  cursor: pointer;
  display: inline-block;
  white-space: nowrap;
  border-radius: 4px;
  background: #e6e5e5;
  color: #666666;
  border: 0; }

  .c-button--text {
    background: transparent;
    color: #424446;
    box-shadow: none; }

.u-hide {
  display: none !important; }


/* time */

.msg::before {
  content: attr(data-time);
  font-size: 0.8rem;
  position: absolute;
  bottom: 100%;
  color: #888;
  white-space: nowrap;
  /* Hidden by default */
  display: none;
}

.msg.sent::before {
  right: 16px;
}

.msg.rcvd::before {
  left: 16px;
}

/* Show time only for first message in group */

.msg:first-child::before,
.msg.sent+.msg.rcvd::before,
.msg.rcvd+.msg.sent::before {
  /* Show only for first message in group */
  display: block;
}

.working {
  background: #F2F2F2;
  display: inline-block;
  border-radius: 1em;
  margin-top: 0.5em;
  padding: 0 0.5em;
}
.working span {
  content: "";
  -webkit-animation: blink 1.5s infinite;
          animation: blink 1.5s infinite;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  height: .6em;
  width: .6em;
  line-height: 0.6em;
  display: inline-block;
  background: #919191;
  border-radius: 50%;
  margin: 0.1em;
}
.working span:nth-child(2) {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}
.working span:nth-child(3) {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}

@-webkit-keyframes blink {
  0% {
    opacity: 0.1;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
}

@keyframes blink {
  0% {
    opacity: 0.1;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
}

.pill{
  padding: 0 4px 0 4px;
  border-radius: 5px;

}

